import $ from 'jquery'
import * as project from './functions'
import * as anim from './animation'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.animateLoader()
  project.smoothScroll()
  project.menuOverlay()
  project.blockSearchToggle()
  project.setupModalVideo()
  project.contactsOpenDropdown()
  project.contactsManagePrivacyCheckbox()
  anim.animHeader()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        project.homeReloadOnResize()
        anim.animHomeLogo()
        anim.animHomeHero()
        break
      case "page-template-about":
        anim.animGallery()
        project.aboutOpenAccordion()
        project.aboutSlideToggle()
        project.aboutSetWidthForReport()
        project.aboutSetupBoard()
        break
      case "tax-service_category":
      case "tax-case_category":
      case "single-service":
      case "single-case":
        project.serviceTabsSetup()
        project.serviceToggleTimeline()
        project.serviceDuplicateMedia('.service__content-wrapper')
        project.serviceDuplicateMedia('.case__content-wrapper')
        break
      case "page-template-archive-news":
        project.archiveNewsCalcHeight()
        project.archiveNewsYearSelect()
        break
      case "single-post":
        project.postCalcHeight()
        break
      case "single-awsm_job_openings":
        project.jobCalcHeight()
        project.jobAddTargetBlank()
        break
      case "page-template-careers-landing":
        project.careersToggleProcess()
        break
      default:
    }
  })
}

$(window).on('facetwp-loaded', function () {
  var facets_in_use = ('' != FWP.buildQueryString())
  facets_in_use ? 
    $('.facetwp-type-reset .facetwp-reset').removeClass('bg--primary') : 
    $('.facetwp-type-reset .facetwp-reset').addClass('bg--primary')
})

$(document).on('change','.facetwp-type-dropdown select', function () {
  FWP.refresh()
})