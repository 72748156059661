import $ from 'jquery'
import LazyLoad from "vanilla-lazyload"
import { gsap, Power1, Linear } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CustomEase } from "gsap/CustomEase"
gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollTrigger)

/* ANIMATE TEAM GALLERY
  ----------------------------- */
const createGallery = () => {
  var _osTop = 0
  var _osLeft = 0
  var _size = $('.section__gallery-item').length
  var _breckPoints = []
  var _val = 0

  $('.section__gallery-items.clone').remove()

  for (var i = 1; i <= _size / 3; i++) {
    _breckPoints.push(_val += 3)
    _breckPoints.push(_val += 2)
  }

  $('.section__gallery-item').each(function (i) {
    const gutter = $(window).height() / 5
    var n = i + 1
    var _randLeft = 32 - Math.floor(Math.random() * 64)
    var _randTop = Math.floor(Math.random() * 32)
    var _posLeft = _osLeft + _randLeft
    var _posTop = _osTop + _randTop
    var _randScale = Math.floor(Math.random() * (100 - 60) + 60) / 100

    _osTop += gutter
    _breckPoints.forEach(function (item, index) {
      if (item == n) {
        _osTop = 0
        _osLeft += gutter * 1.4
      }
    });

    if (n % 3 == 0) {
      _osTop += gutter / 2
    }

    $(this).css({
      'top': _posTop + 'px',
      'left': _posLeft + 'px',
      'transform': 'scale(' + _randScale + ')',
    })
  })

  var wrapperWidth = $('.section__gallery-wrapper .section__gallery-item:last-child').offset().left + $('.section__gallery-wrapper .section__gallery-item:last-child').width() * 1.5
  $('.section__gallery-wrapper').css('width', wrapperWidth * 2)
  $('.section__gallery-items').clone().appendTo('.section__gallery-wrapper').css('left', wrapperWidth).addClass('clone')
  gsap.to('.section__gallery-wrapper', { duration: 45, x: -wrapperWidth, repeat: -1, ease: Linear.easeNone })
  gsap.to('.section__gallery-item', { duration: 1, autoAlpha: 1, stagger: .07, delay: 1 })
}

const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

export const animGallery = () => {
  createGallery()
  lazyLoad()
  var w = $(window).width()

  $(window).on('resize', function () {
    if ($(window).width() == w) return
    w = $(window).width()
    createGallery()
  })
}

/* ANIMATE HOME HEADER
  ----------------------------- */
export const animHeader = () => {
  let heroHeight = $('.section--home-hero .section__header').length > 0 ? $('.section--home-hero .section__header').innerHeight() : $(window).width() < 640 ? 320 : 460;
  const headerNewHeight = heroHeight + $('.header--main').innerHeight()
    
  const tl = gsap.timeline({ paused: true, reversed: true })
  tl.to('.header--main', { height: (headerNewHeight), backgroundColor: '#001431', ease: "power1.out", duration: .35})
  tl.to('.header__menu-item', { color: '#fff', duration: .35 }, '-=.35')
  tl.from('.header__menu-wrapper', { y: -20, autoAlpha: 0, duration: .5 }, '-=0')
  tl.from('.header__opened-logo-wrapper', { y: -20, autoAlpha: 0, duration: .5 }, '-=.35')

  document.querySelector('.header__menu-item').addEventListener('click', function () {
    tl.reversed() ? tl.play() : tl.reverse()
  })
}

/* ANIMATE HOME LOGO
  ----------------------------- */
export const animHomeLogo = () => {
  let fromTop = $('body').hasClass('admin-bar') ? 72 : 40
  let logoWidth = $(window).width() > 832 ? 180 : 110
  let logoX = $(window).width() > 832 ? 18 : 0

  if ($(window).width() < 832) {
    fromTop = $('body').hasClass('admin-bar') ? 70 : 20
  }

  const tl = gsap.timeline({
    paused: true, 
    reversed: false,
    scrollTrigger: {
      trigger: '.section--home-hero .section__logo-wrapper',
      start: 'top top+=' + (fromTop + 120 + 96),
      end: '+=200',
      scrub: true,
      // markers: true,
      onLeave: () => ScrollTrigger.refresh(),
      onEnterBack: () => ScrollTrigger.refresh(),
    } 
  })

  tl.to('.section--home-hero .section__logo', { width: logoWidth })
  tl.to('.section--home-hero .section__logo', { x: logoX }, '-=.5')
  tl.to('.header--main .header__logo-wrapper', { x: 0 }, '-=.5')

  ScrollTrigger.create({
    trigger: ".section--home-hero .section__logo-wrapper",
    start: "top top+=" + (fromTop - 2),
    endTrigger: "html",
    end: "bottom top",
    pin: ".section--home-hero .section__logo",
    pinSpacing: false,
    // markers: true,
  })
}

/* ANIMATE HOME HERO
  ----------------------------- */
export const animHomeHero = () => {
  let video = document.querySelector('.section--home-hero video.section__video')
  const tl = gsap.timeline({
    paused: true, 
    scrollTrigger: {
      trigger: '.section--home-hero .section__animation-wrapper',
      start: 'top top+=35%',
      end: '+=100%',
      // markers: true,
      onEnter: () => { 
        tl.play() 
        video.play()
      },
      onLeaveBack: () => tl.reverse(),
    } 
  })

  tl.to('.section--home-hero .section__body', { yPercent: -50, autoAlpha: 1, ease: "power1.out", duration: 1 })
}