import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import ModalVideo from 'modal-video'
// import 'jquery-modal'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      autoplay: false,
      infinite: true,
    },
    // board: {
    //   infinite: false,
    //   variableWidth: true,
    //   responsive: [{
    //     breakpoint: 640,
    //     settings: {
    //       variableWidth: false,
    //     }
    //   }]
    // },
    report: {
      infinite: false,
      variableWidth: true,
      // slidesToShow: 4,
    },
    blogYears: {
      infinite: false,
      slidesToShow: 3,
    },
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle, .header__menu-item')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header--main, .content-area, body')
  const link = $('.header--offcanvas a');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  $(document).scrollTop() > 400 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 400 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* TOGGLE SEARCH
  ----------------------------- */
export const blockSearchToggle = () => {
  $('.block--search-form .block__icon').on('click', function () {
    $('body').toggleClass('search-open')
    $('.block--search-form').toggleClass('open')
    $('.block--search-form input[type="text"]').focus()
  })
}

/* ANIMATE LOADER
  ----------------------------- */
export const animateLoader = () => {
  const videoLogo = $('.section--home-hero .section__logo')

  if ($('#loader').length > 0) {
    videoLogo.trigger('pause')
    $('#loader').delay(2000).fadeOut(1000)
    setTimeout(function () {
      videoLogo.trigger('play')
    }, 1500)
  }
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* HOME RELOAD ON RESIZE
  ----------------------------- */
export const homeReloadOnResize = () => {
  var currentWidth = $(window).width()

  $(window).on('resize', function () {
    var newWidth = $(window).width()
    if (newWidth !== currentWidth) { location.reload() }
    currentWidth = newWidth;
  })
}

/* ABOUT SETUP BOARD
  ----------------------------- */
export const aboutSetupBoard = () => {
  $('.section--about-board .section__slide').on('click', function () {
    $(this).addClass('active').siblings().removeClass('active')
  })

  $('.section--about-board .section__arrow-prev').on('click', function () {
    var current = $('.section--about-board .section__slide.active')
    var prev = current.prev().length ? current.prev() : current.siblings('.section__slide').last()
    prev.addClass('active').siblings('.section__slide').removeClass('active')
  })

  $('.section--about-board .section__arrow-next').on('click', function () {
    var current = $('.section--about-board .section__slide.active')
    var next = current.next().length ? current.next() : current.siblings('.section__slide').first()
    next.addClass('active').siblings('.section__slide').removeClass('active')
  })
}

/* ABOUT OPEN ACCORDION VALUES
  ----------------------------- */
export const aboutOpenAccordion = () => {
  $('.section--about-values .section__value').on('click', function () {
    $('.section__value').not(this).removeClass('open').find('.section__value-body').slideUp()
    $(this).toggleClass('open').find('.section__value-body').slideToggle()
  })
}

/* ABOUT SLIDE TOGGLE CONTENT
  ----------------------------- */
export const aboutSlideToggle = () => {
  $('.section--about-membership .section__slide').each(function () {
    const moreBtn = $(this).find('.section__more')
    const content = $(this).find('.section__content')
    const info = $(this).find('.section__info,.section__logo-wrapper')

    moreBtn.on('click', function () {
      content.toggleClass('show')
      info.toggleClass('hidden')
    })
  })

  // SLIDER ON CHANGE REMOVE CLASSES
  $('.section--about-membership .section__slider').on('afterChange', function (event, slick, currentSlide) {
    $('.section--about-membership .section__content').removeClass('show')
    $('.section--about-membership .section__info,.section--about-membership .section__logo-wrapper').removeClass('hidden')
  })
}

/* ABOUT SET WIDTH FOR REPORT
  ----------------------------- */
export const aboutSetWidthForReport = () => {
  const calculateWidth = () => {
    let sliderSlot = $(window).width() > 832 ? 4 : $(window).width() > 640 ? 3 : 2
    var sliderWidth = $('.section--about-report .section__slider .slick-list').width()
    $('.section--about-report .section__slider .slick-slide').each(function () {
      $(this).width(sliderWidth / sliderSlot)
    })

    if ($(window).width() > 640) $('.section--about-report .section__slider .slick-slide:first-child').width(sliderWidth / sliderSlot * 2)
  }

  calculateWidth()

  $(window).on('resize', function () {
    calculateWidth()
  })
}

/* CONTACTS OPEN DROPDOWN
  ----------------------------- */
export const contactsOpenDropdown = () => {
  $('.section--common-contacts .section__cta').on('click', function () {
    $(this).toggleClass('active').next().slideToggle()
    $(this).parents('.section__content-wrapper,.section--common-contacts').toggleClass('active')
  })
}

/* CONTACTS MANAGE PRIVACY CHECKBOX
  ----------------------------- */
export const contactsManagePrivacyCheckbox = () => {
  $('.section--common-contacts .section__privacy-confirmation span,.section--contacts-list .section__privacy-confirmation span').on('click', function () {
    $(this).parent().toggleClass('checked')
    $(this).parent().siblings().toggleClass('hidden')
  })
}

/* SERVICE TOGGLE TIMELINE
  ----------------------------- */
export const serviceToggleTimeline = () => {
  $('.service__timeline-btn').on('click', function () {
    $(this).toggleClass('active')
    $('.service__timeline').toggleClass('open')
  })
}

/* SERVICE TABS SETUP
  ----------------------------- */
export const serviceTabsSetup = () => {
  const catTitle = $('.service__category-title')
  const caseLink = $('.service__case-link.active')
  const subArrow = $('.service__subcategories-arrow')

  catTitle.on('click', function () {
    if (caseLink.length > 0) caseLink.removeClass('active')

    catTitle.not(this).removeClass('active')
    $(this).addClass('active')
    $('#' + $(this).data('subcategories')).addClass('active').siblings().removeClass('active')

    if ($(window).width() < 832 || ($(window).width() > 832 && window.innerHeight > window.innerWidth)) {
      $('.service__categories-main').removeClass('active')
    }
  })

  subArrow.on('click', function () {
    $(this).parents('.service__subcategories-main').removeClass('active')
    $('.service__categories-main').addClass('active')
    $('.service__category-title').removeClass('active')
  })
}

/* SERVICE DUPLICATE MEDIA PLACEHOLDER
  ----------------------------- */
export const serviceDuplicateMedia = (activeElement) => {
  var contentHeight = $(activeElement).find('[class*="__column-content"]').innerHeight()
  var dupes = Math.floor(contentHeight / 650)

  // For each dupes, clone the first video and append it to the media container, 
  // then set the currentTime to 5 seconds for each iteration
  for (var i = 0; i < dupes; i++) {
    $(activeElement).find('[class*="__column-media"] [class*="__video-wrapper"]:first-child').clone().appendTo($(activeElement).find('[class*="__column-media"]')).find('video')[0].currentTime = 5 * (i + 1)
  }
}

/* ARCHIVE NEWS CALC HEIGHT
  ----------------------------- */
export const archiveNewsCalcHeight = () => {
  const calculateHeight = () => {
    if ($(window).width() > 832) {
      var containerHeight = $(window).height() - $('.header--main').innerHeight() - $('.section__header').innerHeight()
      $('.section__container').height(containerHeight)
    }
  }

  calculateHeight()

  $(window).on('resize', function () {
    calculateHeight()
  })
}

/* ARCHIVE NEWS CONTROL YEAR SELECT
  ----------------------------- */
export const archiveNewsYearSelect = () => {
  $('.section__year').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
      $("select.facetwp-dropdown").val('').change()
    } else {
      $('.section__year').removeClass('active')
      $(this).addClass('active')
      $("select.facetwp-dropdown").val($(this).data('year')).change()
    }
  })
}

/* POST CALC HEIGHT
  ----------------------------- */
export const postCalcHeight = () => {
  const calculateHeight = () => {
    if ($(window).width() > 832) {
      var containerHeight = $(window).height() - $('.header--main').innerHeight() - $('.post__header').innerHeight() - $('.post__title-wrapper').innerHeight()
      $('.post__content-wrapper').height(containerHeight)
    }
  }

  calculateHeight()

  $(window).on('resize', function () {
    calculateHeight()
  })
}

/* JOB CALC HEIGHT
  ----------------------------- */
export const jobCalcHeight = () => {
  const calculateHeight = () => {
    if ($(window).width() > 832) {
      var containerHeight = $(window).height() - $('.header--main').innerHeight() - $('.job__title-wrapper').innerHeight()
      $('.job__content-wrapper').height(containerHeight)
    }
  }

  calculateHeight()

  $(window).on('resize', function () {
    calculateHeight()
  })
}

/* CAREERS TOGGLE PROCESS
  ----------------------------- */
export const careersToggleProcess = () => {
  $('.section--careers-recruiting .section__label-wrapper').on('click', function () {
    $(this).toggleClass('active')
    $('.section--careers-process').slideToggle()
  })
}

/* JOB ADD TARGET BLANK TO LINKS
  ----------------------------- */
export const jobAddTargetBlank = () => {
  $('.job__body a').attr('target', '_blank')
}